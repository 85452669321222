export const mobileCheck = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgent.substr(0, 4));
};

export const validateRequired = (requiredList, values) => {
    const errors = requiredList.reduce((acc, field) => {
        if (!values[field]) {
            acc[field] = true;
        }
        return acc;
    }, {});
    return Object.keys(errors).length ? errors : false;
};

export const htmlEditorToolBar = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean'],
    ],
};

export const getCourseObject = (data, cParent = 0) => {
    let courseObject = data.filter(al => al.parent === cParent);
    let extra = data.filter(al => al.parent !== cParent);

    const createCourseObject = (arr, path = [], parent = 0) => {
        let filteredData = extra.filter(f => f.parent === parent);
        extra = extra.filter(f => f.parent !== parent);

        if (filteredData.length > 0) {
            arr.children = filteredData;
            arr.path = path;

            filteredData.forEach(course => createCourseObject(course, [...path, course.id], course.id));
        }
    };

    courseObject.forEach(course => createCourseObject(course, [course.id], course.id));
    return courseObject;
};

export const getCourseParents = (rawData, treeView, childId) => {
    let hierarchy = [];
    let categoryOption = [{ type: 'Category', children: treeView }];
    let questionSelection = {};

    const setChildren = (tree, id) => {
        const node = tree.find(t => t.id === id);
        questionSelection[node.type] = node.id;
        if (node?.children) {
            categoryOption.push({ type: node.children[0]?.type, children: node.children });
            return node.children;
        }
    };

    const findParent = (cId) => {
        const node = rawData.find(rd => rd.id === cId);
        if (node) hierarchy = [node, ...hierarchy];
        if (node.parent !== 0) findParent(node.parent);
    };

    findParent(childId);
    let tempParent = treeView;

    for (let h of hierarchy) {
        tempParent = setChildren(tempParent, h.id);
    }

    questionSelection['Category'] = hierarchy[0]?.id;
    return [questionSelection, categoryOption];
};

export const getCourseParentsTillSubscription = (rawData, treeView, childId) => {
    let hierarchy = [];
    let categoryOption = [{ type: 'Category', children: treeView }];
    let questionSelection = {};
    let subscriptionFound = false;

    const setChildren = (tree, id) => {
        const node = tree.find(t => t.id === id);
        questionSelection[node.type] = node.id;
        if (node?.children) {
            categoryOption.push({ type: node.children[0]?.type, children: node.children });
            return node.children;
        }
    };

    const findParent = (cId) => {
        const node = rawData.find(rd => rd.id === cId);
        if (node) hierarchy = [node, ...hierarchy];
        if (node.parent !== 0) findParent(node.parent);
    };

    findParent(childId);
    let tempParent = treeView;

    for (let h of hierarchy) {
        tempParent = setChildren(tempParent, h.id);
    }

    questionSelection['Category'] = hierarchy[0]?.id;

    categoryOption = categoryOption.filter(co => {
        const keep = !subscriptionFound;
        if (!subscriptionFound) {
            subscriptionFound = co.children.some(s => s.for_subscription);
        }
        return keep;
    });

    return [questionSelection, categoryOption];
};

export const getDocumentAccessUrl = (path) => {
    return path.includes('digitaloceanspaces') ? path : `/uploads/${path}`;
};

export const secondToMinConvertor = (second) => (second / 60).toFixed(2);

export const QUESTION_DIFFICULTY = [
    { value: 1, label: 'Easy' },
    { value: 2, label: 'Medium' },
    { value: 3, label: 'Hard' },
    { value: 4, label: 'Very Hard' },
];

export const selectionOption = [
    { value: 1, label: 'Single Select' },
    { value: 2, label: 'Multi Select' },
];

export const questionType = [
    { value: 1, label: 'Objective' },
    { value: 2, label: 'Subjective' },
];

export const subjectQuestionLength = [
    { value: 1, label: 'Very Short Question' },
    { value: 2, label: 'Short Question' },
    { value: 3, label: 'Long Question' },
];

export const formValidation = {
    2: ['firstName', 'lastName', 'dob', 'gender', 'category', 'fathersName', 'nominee', 'relationNominee', 'maritalStatus', 'passport_photo', 'offense'],
    3: ['present_line1', 'present_line2', 'present_landmark', 'present_city', 'present_state', 'present_pin', 'present_country', 'permanent_line1', 'permanent_line2', 'permanent_landmark', 'permanent_city', 'permanent_state', 'permanent_pin', 'permanent_country'],
    4: ['hsc_board', 'hsc_school', 'hsc_location', 'hsc_passing', 'hsc_mark_secured', 'hsc_percentage', 'hsc_certificate', 'p2_board', 'p2_college', 'p2_location', 'p2_passing', 'p2_mark_secured', 'p2_percentage', 'p2_certificate', 'graduation_board', 'graduation_college', 'graduation_location', 'graduation_passing', 'graduation_mark_secured', 'graduation_percentage', 'graduation_certificate', 'llb_college', 'llb_location', 'llb_passing', 'llb_mark_secured', 'llb_percentage', 'llb_practice', 'llb_certificate', 'llb_provisional'],
};
