import React, { useState, useEffect } from "react";
import './navbar.css';
import { useLocation, useNavigate } from "react-router-dom";
import {
    AppBar,
    Toolbar,
    useTheme,
    useMediaQuery,
    Button,
    Box,
    Typography
} from "@mui/material";
import { Link } from "react-router-dom";
import styled from '@emotion/styled';
import NavDrawer from "../navdrawer/navdrawer";
import logo from "../../../../assets/logos/logo.png";
// import ChatBubbleOutlinedIcon from '@mui/icons-material/ChatBubbleOutlined';
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Login from "../../../../components/login/login";
import useAuth from "../../../../customhooks/user/useauth";
import Profile from "../../../../components/profile";
import { useSelector } from "react-redux";
import AdminToolBar from './admintoolbar';
import useInfo from "../../../../customhooks/user/useinfo";
import Notification from "../../../../components/notification/notification";
// import Fab from '@mui/material/Fab';
// import HomeIcon from '@mui/icons-material/Home';
// import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
// import InfoIcon from '@mui/icons-material/Info';
// import PersonIcon from '@mui/icons-material/Person';
// import Person3Icon from '@mui/icons-material/Person3';
// import PhoneIcon from '@mui/icons-material/Phone';
// import BookIcon from '@mui/icons-material/Book';
// import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import getUserInfo from "../../../../customhooks/user/useinfo";
import { hasSubscriptionExpired } from "../../../../utils/utils";
// import { idID } from "@mui/material/locale";
import { encryptStorage } from "../../../../utils/storage";

const useStyles = styled((theme) => ({
    navlinks: {
        marginLeft: theme.spacing(5),
        display: "flex",
    },
    logo: {
        flexGrow: "1",
        cursor: "pointer",
    },
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "20px",
        marginLeft: theme.spacing(20),
        borderBottom: "1px solid transparent",
        "&:hover": {
            color: "yellow",
            borderBottom: "1px solid white",
        },
    },
}));
function Navbar() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const auth = useAuth();
    const profileInfo = useInfo();
    const [userInfo, setUserInfo] = useState(getUserInfo());
    const location = useLocation();
    const [pathName, setPathName] = useState('/');
    const [isLogin, setLogin] = useState(false);
    const [isAdmin, setAdmin] = useState(false);
    const loginInfo = useSelector(state => state.login.result);

    const navigate = useNavigate();
    const handleClose = () => {
        setLogin(false);
    }
    const profile = useSelector(state => state.profile.result);
    const isLoginSignup = useSelector(state => state.login.isLoginSignup);
    useEffect(() => {
        const pName = location.pathname;
        if (!auth) {
            // navigate('/');
            // return;
            setLogin(false);
        }
        if (pName.includes('/admin')) {
            setAdmin(true);
        }
        setPathName(pName);
    }, [location.pathname]);

    useEffect(() => {
        setUserInfo(getUserInfo());
    }, [location.pathname, loginInfo]);

    useEffect(() => {
        if (profile && profile?.data) {
            encryptStorage.setItem("user-info", profile.data);
            setUserInfo(profile.data);
        }
    }, [profile]);

    useEffect(() => {
        if (isLoginSignup) {
            setLogin(true);
        }
    }, [isLoginSignup])

    const getSubscriptionMessage = () => {
        if (userInfo) {
            if (!userInfo.course_id && !userInfo.subscription) {
                return 'Subscribe to get full access';
            } else if (hasSubscriptionExpired()) {
                return 'Subscription expired';
            } else if (userInfo.subscription === 'Free') {
                return 'Go for premium to get full access';
            }
        } else {
            return ''
        }
        return false;
    }

    const popOverAction = () => {
        if (userInfo) {
            navigate('/course-subscription')
        } else {
            setLogin(true);
        }
    }

    return (
        <>
            {isAdmin
                ? <AdminToolBar />
                :
                <>
                    {/*auth && !userInfo?.enrolled &&
                        <Box className="call-out-button">
                            <Fab
                                variant="extended"
                                color="primary"
                                className="animate__flash animate__animated animate__infinite animate__slow"
                                onClick={() => navigate('/enrollment')}
                            >
                                <AccountBalanceIcon sx={{ mr: 1 }} />
                                Enroll Now
                            </Fab>
                        </Box>
                    */}
                    {/* {getSubscriptionMessage() &&
                        <Box className="subscribe-button">
                            <Fab
                                variant="extended"
                                color="primary"
                                onClick={popOverAction}
                            >
                                <AccessTimeIcon sx={{ mr: 1 }} />
                                {getSubscriptionMessage()}
                            </Fab>
                        </Box>
                    } */}
                    <AppBar position="fixed">
                        <Toolbar>
                            {isMobile ? (
                                <NavDrawer />

                            ) : (
                                <>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <img src={logo} alt="img-logo" style={{ height: "30px" }} />
                                    </Box>
                                    <Box sx={{ flex: 1 }}></Box>
                                    <Link to="/" className={`${classes.link} link-main ${pathName === '/' && 'selected'}`}>
                                        <Box>
                                            <Typography>Home</Typography>
                                        </Box>
                                    </Link>
                                    <Link to="/about" className={`${classes.link} link-main ${(pathName === '/about' || pathName.includes('/about/')) && 'selected'}`}>
                                        <Box>
                                            <Typography>About Us</Typography>
                                        </Box>
                                    </Link>
                                    <Link to="/tickers/AAPL" className={`${classes.link} link-main ${(pathName === '/tickers' || pathName.includes('/tickers/')) && 'selected'}`}>
                                        <Box>
                                            <Typography>Tickers</Typography>
                                        </Box>
                                    </Link>
                                    <Link to="/members" className={`${classes.link} link-main ${pathName === '/members' && 'selected'}`}>
                                        <Box>
                                            <Typography>Members</Typography>
                                        </Box>
                                    </Link>
                                    <Link to="/contact" className={`${classes.link} link-main ${pathName === '/contact' && 'selected'}`}>
                                        <Box>
                                            <Typography>Contact Us</Typography>
                                        </Box>
                                    </Link>
                                    {!auth ?
                                        <Button variant="contained" color="secondary" size="small" onClick={() => { setLogin(true) }}>Login/Signup</Button>
                                        : <>
                                            {/* <Button
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                sx={{ mr: 1 }}
                                                endIcon={<ChatBubbleOutlinedIcon />}
                                                onClick={() => navigate('/forum')}
                                            >
                                                Forum
                                            </Button> */}
                                            <Link to="/search" className={`${classes.link} link-main ${pathName === '/search' && 'selected'}`}>
                                                <Box>
                                                    <Typography>Search</Typography>
                                                </Box>
                                            </Link>
                                            {/* <Notification /> */}
                                            <Profile userInfo={userInfo} />
                                        </>
                                    }
                                </>
                            )}
                        </Toolbar>
                    </AppBar>
                    <Toolbar />
                    <Login open={isLogin} close={handleClose} />
                </>
            }
        </>
    );
}
export default Navbar;