import * as stocktypes from '../types/stocktypes';

const initialState = {
    list: {},
    loading: false
};

export default function stockReducer(state = initialState, action) {
    switch (action.type) {
        case stocktypes.ON_STOCK_LOAD_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.name]: action.payload
                },
                loading: action.payload.loading
            }
        case stocktypes.ON_ALL_STOCK_LOAD_SUCCESS:
            const result = action.payload.reduce((acc, curr) => {
                acc[curr.stockName] = curr.data;
                return acc;
            }, {});
            return {
                ...state,
                list: {
                    ...state.list,
                    ...result
                }
            }
        case stocktypes.ON_STOCK_LOAD_FAILURE:
            return {
                ...state,
                result: action.payload.data
            }
        default:
            return state;
    }
}