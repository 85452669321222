import React, { useEffect, useState } from "react";
import { Menu, MenuItem, ListItemIcon, Divider, Avatar, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import useInfo from "../../customhooks/user/useinfo";
import { ChatBubbleOutlineOutlined, ExitToApp as ExitToAppIcon, Person as PersonIcon } from '@mui/icons-material';
import { deleteLoginState } from "../../redux/actions/loginaction";
import { encryptStorage } from "../../utils/storage";

export default function Profile({ userInfo: propsUserInfo }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [isAdmin, setAdmin] = useState(false);
    const userInfo = useInfo();

    useEffect(() => {
        if (propsUserInfo) {
            const { firstName, lastName } = propsUserInfo;
            setFullName(`${firstName} ${lastName}`);
            setAdmin(false);
        } else {
            setAdmin(true);
        }
    }, [propsUserInfo]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        if (isAdmin) {
            encryptStorage.removeItem('admin-info');
            navigate('admin/login');
        } else {
            encryptStorage.removeItem('user-info');
            navigate('/');
        }
        deleteLoginState();
    };

    const openChatWithUs = () => {
        window.open('https://wa.me/919090951010', '_blank');
    };

    return (
        <>
            <Tooltip>
                <IconButton variant="contained" onClick={handleClick}>
                    {userInfo?.profilePic ? <Avatar src={userInfo.profilePic} /> : <PersonIcon />}
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                {!isAdmin && (
                    <>
                        <MenuItem>{fullName}</MenuItem>
                        <Divider />
                        <MenuItem onClick={() => navigate('/profile')}>
                            <ListItemIcon>
                                <PersonIcon fontSize="small" />
                            </ListItemIcon>
                            Profile
                        </MenuItem>
                        <MenuItem onClick={openChatWithUs}>
                            <ListItemIcon>
                                <ChatBubbleOutlineOutlined fontSize="small" />
                            </ListItemIcon>
                            Chat With Us
                        </MenuItem>
                    </>
                )}
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
}
